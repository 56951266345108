import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { IFeedbackData } from '@shared/model/feedback';
import { IDownloadFile } from '@shared/model/file';
import type { FeedbackAPI } from '../../../../../services/lib/feedback/feedback.api';
import { Service } from '../service/service';
import { UtilService } from '../../services/util/util.service';

@Injectable()
export class FeedbackService extends Service<typeof FeedbackAPI> {
    subscribe() {
        return this.ws('/feedback/subscribe');
    }

    give({ feedback, session, rating, files }: { feedback: string; session?: number; rating: number; files: File[] }) {
        const body = new FormData();

        if (session !== undefined && session !== null) {
            body.append('session', session + '');
        }
        if (feedback !== undefined && feedback !== null) {
            body.append('feedback', feedback);
        }
        if (rating !== undefined && rating !== null) {
            body.append('rating', rating + '');
        }
        if (files && files.length) {
            for (let file of files) {
                body.append('files', file);
            }
        }
        return this.post('/feedback/give', {
            body
        });
    }

    async view(params: { id: number }): Promise<IFeedbackData> {
        try {
            const resp = await firstValueFrom(
                this.get('/feedback/view', {
                    params
                })
            );
            if (!resp.success) {
                console.error('feedback.view failed: ', resp.error);
                throw new Error('feedback.view failed: ' + UtilService.getErrorMessage(resp.error));
            }
            return resp.data;
        } catch (err) {
            console.error('Error calling feedback.view: ', err);
            throw err;
        }
    }

    async download(params: { id: number | string }): Promise<IDownloadFile> {
        try {
            const response = await firstValueFrom(
                this.get('/feedback/download', {
                    params
                })
            );
            if (!response.success && response.error) {
                console.error("feedback.service.download failed: ", response);
                throw response.error;
            }
            return response.data;
        } catch (err) {
            console.error('Error downloading feedback file: ', err);
            throw err;
        }
    }

    page(params: { top: number; limit: number }) {
        return this.get('/feedback/page', {
            params
        });
    }
}
