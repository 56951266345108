import { NgModule } from '@angular/core';
import { EnvHeaderComponent } from './env-header.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [
        TranslocoModule
    ],
    declarations: [
        EnvHeaderComponent
    ],
    exports: [
        EnvHeaderComponent
    ],
    bootstrap: [EnvHeaderComponent]
})
export class EnvHeaderModule {}
