import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { IDownloadFile } from '@shared/model/file';
import { Service } from '../service/service';
import type { PDFAPI } from '../../../../../services/lib/pdf/pdf.api';

@Injectable()
export class PDFService extends Service<typeof PDFAPI> {
    async download(params: { session: number }): Promise<IDownloadFile> {
        try {
            const response = await firstValueFrom(
                this.get('/pdf/download', {
                    params
                })
            );
            if (!response.success && response.error) {
                console.error("pdf.service.download failed: ", response);
                throw response.error;
            }
            return response.data;
        } catch (err) {
            console.error('Error calling pdf download: ', err);
            throw err;
        }
    }

    async preview(params: { sap: string, session: number }): Promise<IDownloadFile> {
        try {
            const response = await firstValueFrom(this.get('/pdf/preview', {
                params
            }
            ));
            if (!response.success && response.error) {
                console.error("pdf.service.preview failed: ", response);
                throw response.error;
            }
            return response.data;
        } catch (err) {
            console.error('Error generating preview pdf: ', err);
            throw err;
        }
    }

}
