import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { WatermarkComponent } from './watermark.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        TranslocoModule
    ],
    declarations: [
        WatermarkComponent
    ],
    exports: [
        WatermarkComponent
    ],
    bootstrap: [WatermarkComponent]
})
export class WatermarkModule {}
