import { IDownloadFile } from '@shared/model/file';

export class FileUtil {
    public static async download(file: IDownloadFile): Promise<void> {
        const url = file.signedUrl;
        const a = document.createElement('a');

        const name = file.fileName;
        a.target = '_blank';
        a.download = name || 'download';
        a.href = url;

        a.click();
        URL.revokeObjectURL(url);
    }
}
