import { Component, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { EnvironmentEnum } from '@shared/model/environment';
import { IRL } from './globals/irl';
import { IUserClaims } from '@shared/model/user';
import { UserState } from './states/user/user.state';
import { VersionState } from './states/version/version.state';
import { AuthStatusEnum, AuthState } from './states/auth/auth.state';
import { MADAlertService } from './services/alert/alert.service';
import { ENV } from './globals/env';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    @ViewChild('title') title: ElementRef<any>;

    private status: AuthStatusEnum = AuthStatusEnum.PENDING;

    public errorMessage: string;

    public errorCode: string;

    public IRL = IRL;

    private version: string;

    public updateRequired = false;

    public systemMessage: string;

    public feedbackVisible = false;

    private isSAP = false;

    public initCalled = false;

    public showEnvironment: boolean;

    public showWatermark: boolean;

    public envName: string;

    public showTrainingLink = true;

    public showProdLink = true;

    public showAdminLink = true;

    public constructor(
        public router: Router,
        public msal: MsalService,
        public authState: AuthState,
        public userState: UserState,
        public changeDetector: ChangeDetectorRef,
        public versionState: VersionState,
        public alertService: MADAlertService
    ) {
        authState.status.subscribe((v: AuthStatusEnum): void => {
            this.status = v;
            if (this.status === AuthStatusEnum.FAILURE) {
                this.errorMessage = this.authState.errorMessage;
                this.errorCode = this.authState.errorCode;
            }
        });

        versionState.status.subscribe((v): void => {
            if (this.version === undefined || this.version === null) {
                this.version = v;
            }
            if (this.version !== v) {
                console.log('applicationUpdated, suggesting reload');
                this.version = v;
                this.updateRequired = true;
            }
        });

        userState.changes.subscribe((v: IUserClaims): void => {
            this.showAdminLink = this.userState.state.is_admin === true;
        });
    }

    ngOnInit(): void {
        setTimeout((): void => {
            this.initCalled = true;
        });

        const currentEnv = ENV.getEnvironment();
        this.showTrainingLink = !ENV.isTraining();
        this.showProdLink = ENV.isTraining();
        this.showEnvironment = !ENV.isProd();
        this.showWatermark = EnvironmentEnum.TRAINING === currentEnv;
        this.envName = currentEnv;
    }

    isSap(): boolean {
        return this.initCalled && this.router.url.startsWith('/sap');
    }

    updateStyles(): void {
        const isSap = this.isSap();
        if (isSap !== this.isSAP) {
            if (isSap) {
                this.title.nativeElement.style.transition = 'none';
            } else {
                this.title.nativeElement.style.transition = null;
            }
        }
        this.isSAP = isSap;
    }

    onTransitionEnd($event: TransitionEvent): void {
        if ($event.target === this.title.nativeElement) {
            this.title.nativeElement.style.transition = 'none';
        }
    }

    getTitleStyle(): void {
        this.updateStyles();
    }

    public isAuthSuccess(): boolean {
        return this.status === AuthStatusEnum.AUTHORIZED;
    }

    public isAuthFailure(): boolean {
        return this.status === AuthStatusEnum.FAILURE;
    }

    public isAuthSsoSuccess(): boolean {
        return this.status === AuthStatusEnum.AUTHENTICATED;
    }

    public isAuthPending(): boolean {
        return this.status === AuthStatusEnum.PENDING;
    }

    async onSignIn(): Promise<void> {
        await this.authState.loginPopup();
    }

    onSignOut(): void {
        this.msal.logout();
    }

    onIconClick(): void {
        if (this.isSAP) {
            return;
        }
        this.router.navigate(['/sap']);
    }

    updateViewUI(access: 'ssa' | 'admin', y: boolean): void {
        if (this.userState.state) {
            this.userState.set(`is_${access}` as 'is_ssa' | 'is_admin', y);
        }
    }

    onUpdateClick(): void {
        window.location.reload();
    }

    goTraining(): void {
        window.location.href = 'https://training.irl.idexxmad.com/';
    }

    goProd(): void {
        window.location.href = 'https://prod.irl.idexxmad.com/';
    }

    goAdmin(): void {
        this.router.navigate(['admin']);
    }
}
