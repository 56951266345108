export enum EnvironmentEnum {
    DEVELOPMENT = "dev",
    QA = "qa",
    STAGE = "stg",
    TRAINING = "training",
    PROD = "prod",
    LOCAL = "local"
}

export class EnvironmentManager {

    public static getEnvironment(envStr: string): EnvironmentEnum {
        return this.parseEnvironment(envStr);
    }

    private static parseEnvironment(env: string): EnvironmentEnum {
        const normalizedEnv = env.trim().toLowerCase();
        const envMap: { [key: string]: EnvironmentEnum } = {
            "dev": EnvironmentEnum.DEVELOPMENT,
            "development": EnvironmentEnum.DEVELOPMENT,
            "qa": EnvironmentEnum.QA,
            "stage": EnvironmentEnum.STAGE,
            "stg": EnvironmentEnum.STAGE,
            "training": EnvironmentEnum.TRAINING,
            "prod": EnvironmentEnum.PROD,
            "production": EnvironmentEnum.PROD,
            "local": EnvironmentEnum.LOCAL
        };

        const parsedEnv = envMap[normalizedEnv];
        if (!parsedEnv) {
            console.error(`Unknown environment: ${env}.  Defaulting to DEVELOPMENT`, normalizedEnv);
            return EnvironmentEnum.DEVELOPMENT;
        }
        return parsedEnv;
    }

    public static isProduction(envStr: string): boolean {
        return this.forString(envStr) === EnvironmentEnum.PROD;
    }

    public static forString(env: string): EnvironmentEnum {
        return this.parseEnvironment(env);
    }

    public static toString(envValue: EnvironmentEnum | string): string {
        let env = envValue;
        if (typeof envValue === 'string') {
            env = EnvironmentManager.forString(envValue);
        }
        if (env === EnvironmentEnum.DEVELOPMENT) {
            return "Development";
        } else if (env === EnvironmentEnum.QA) {
            return "Qa";
        } else if (env === EnvironmentEnum.STAGE) {
            return "Stage";
        } else if (env === EnvironmentEnum.TRAINING) {
            return "Training";
        } else if (env === EnvironmentEnum.PROD) {
            return "Prod";
        } else if (env === EnvironmentEnum.LOCAL) {
            return "Local";
        }
        console.error("Unknown environment: ", env);
        return "";
    }
}
